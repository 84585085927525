<script>
    import { onMount, tick } from "svelte";
    import {
        f7,
        Page,
        Navbar,
        NavRight,
        NavLeft,
        Link,
        List,
        ListItem,
        Button,
        Popup,
        View,
        Block,
        Segmented,
        AccordionContent,
        Preloader,
        Sheet,
        PageContent,
        BlockTitle,
        Chip,
    } from "framework7-svelte";
    import {
        mealPlanStore,
        currentMealPlanStartDate,
        addRecipeToMealPlan,
        selectedRecipe,
        getRecipeDetails
    } from "../stores/mealPlan";
    import {
        addIngredientToGroceryList,
        getGroceryItemsForWeek,
        removeIngredientFromGroceryList,
    } from "../stores/groceries";
    import { showToast } from "../js/generalHelpers";
    import DateSelectSheetModal from "../components/DateSelectSheetModal.svelte";
    import "../css/recipe-details.css";
    import {
        getUserData,
        userDataStore,
        hasMilestone,
        setMilestone,
    } from "../stores/userInfo";
    import {
        userRecipeFavourites,
        addNewFavourite,
        removeFavourite,
        getUserFavourites,
    } from "../stores/recipeFavourites";
    import { supabase } from "../js/supabase";
    import { writable } from "svelte/store";
    import { ExternalLinkIcon } from "svelte-feather-icons";
    import { findMatchingIngredient } from "../data/environmental_data";
    import { swapRecipeIngredient } from "../js/recipeHelpers"

    // recipeId passed in as a prop from the f7 navigation router
    export let recipeId;
    export let addToMealPlan = false;
    export let reloadRecipes;

    let environmentalIngredientReplacementSheetData = writable({
        itemName: "Loading...",
        totalCO2Impact: "Loading...",
        co2Explaination: "Loading...",
        replacements: [],
    });
    let activeIngredientInstructionSwitcher = 0;

    const daysOfTheWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    let zestyImpactBreakdown;

    onMount(() => {
        // Do some basic mounting functionality here if required
    });

    $: onSelectedRecipeChange($selectedRecipe);
    function onSelectedRecipeChange(value) {
        // Dynamic function to allow for waiting for the selected recipe to load from the parent function
        if (value && !value.loadFunctionsRun) {
            fetchGroceryItems();
            generateZestyImpactBreakdown();

            // loadFunctionsRun is to make sure this function doesn't run recursively by
            // any selectedRecipe changes made by the above functions
            value.loadFunctionsRun = true;
            selectedRecipe.set(value);
        }
    }

    function recipeDetailsClose() {
        // Function to run while the popup is starting its closing animation
        if (reloadRecipes) {
            reloadRecipes();
        }
    }

    function recipeDetailsClosed() {
        // Function to run when the popup fully completes its closing animation
        selectedRecipe.set(null);
    }

    // Get current meal plan data
    let currentMealPlan;
    mealPlanStore.subscribe((value) => {
        currentMealPlan = value;
    })();

    function findRecipeById(meals, recipeId) {
        for (let day in meals) {
            for (let meal of meals[day]) {
                const metadata = meal["Extra Metadata"];
                if (metadata && metadata.recipe) {
                    const uri = metadata.recipe.uri;
                    const id = uri.split("#")[1];
                    if (id === recipeId) {
                        return meal["Extra Metadata"]["recipe"];
                    }
                }
            }
        }
        return null;
    }

    function clickLink(target_url) {
        const TARGET = process.env.TARGET || "web"; // Default to Web
        if (TARGET.toLocaleLowerCase() === "cordova") {
            cordova.InAppBrowser.open(target_url, "_system"); // Open app in System's default browser rather than in app
        } else {
            window.open(target_url, "_blank");
        }
    }

    if (recipeId) {
        // Recupe ID passed in, pull it
        const foundRecipe = findRecipeById(currentMealPlan, recipeId);
        selectedRecipe.set(foundRecipe);
    }

    async function fetchGroceryItems() {
        let currentStartDate;
        currentMealPlanStartDate.subscribe((value) => {
            currentStartDate = value;
        })();

        const data = await getGroceryItemsForWeek(currentStartDate);

        let updatedRecipe;
        selectedRecipe.subscribe((value) => {
            updatedRecipe = value;
        });

        for (const [index, ingredient] of updatedRecipe[
            "ingredients"
        ].entries()) {
            let ingredients = updatedRecipe["ingredients"];

            // Get the groceryitem for this ingredient if it exists
            // getIngredientInGroceryItems
            let groceryItem = await getIngredientInGroceryItems(data, ingredient);

            if(groceryItem){
                ingredients[index].added = true;
                ingredients[index].groceryItemId = groceryItem.id;
            } else {
                ingredients[index].added = false;
            }

            updatedRecipe["ingredients"] = ingredients;
            selectedRecipe.set(updatedRecipe);
        }
    }

    function convertMinutesToHours(minutes) {
        const interval = minutes > 15 ? 15 : 5;
        minutes = Math.round(minutes / interval) * interval;
        const hours = minutes / 60;
        let output = "";

        if (hours >= 1) {
            output += `${parseFloat(hours.toFixed(2))} hr${
                hours > 1 ? "s" : ""
            }`;
        } else {
            output += `${minutes} min`;
        }

        return output.trim();
    }

    async function getIngredientInGroceryItems(fetchedGroceryItems, ingredient) {
        if (fetchedGroceryItems) {
            const user = await getUserData();
            const results = fetchedGroceryItems.find(
                (groceryItem) =>
                    groceryItem.plan_recipe_id ==
                        $selectedRecipe["planRecipeId"] &&
                    groceryItem.created_by == user.id &&
                    groceryItem.date == $selectedRecipe["planRecipeDate"] &&
                    groceryItem.name == ingredient.name,
            );

            return results;
        }
    }

    async function handleCartAdd(ingredient) {
        await addIngredientToGroceryList(
            ingredient,
            $selectedRecipe["planRecipeDate"],
            $selectedRecipe["planRecipeId"],
        );

        await fetchGroceryItems();

        showToast(`Item added to grocery cart`);
    }

    async function handleCartRemove(ingredient) {
        await removeIngredientFromGroceryList(ingredient.groceryItemId);

        await fetchGroceryItems();

        showToast(`Item removed from grocery cart`);
    }

    async function addAllItemsToGroceryCart() {
        if (
            $selectedRecipe["ingredients"] &&
            $selectedRecipe["ingredients"].length > 0
        ) {
            for (const ingredient of $selectedRecipe["ingredients"]) {
                await addIngredientToGroceryList(
                    ingredient,
                    $selectedRecipe["planRecipeDate"],
                    $selectedRecipe["planRecipeId"],
                );
            }
        }

        await fetchGroceryItems();

        showToast(`Items added to grocery cart`);
    }

    async function handleAddRecipe(mealPlanDaysSelected) {
        let currentSelectedRecipe;
        selectedRecipe.subscribe((value) => {
            currentSelectedRecipe = value;
        })();

        for (const mealPlanDay of mealPlanDaysSelected) {
            try {
                if (!daysOfTheWeek.includes(mealPlanDay)) {
                    continue; // Break one iteration if this meal plan day is not a valid day of the week
                }

                await addRecipeToMealPlan({
                    day: mealPlanDay,
                    recipe: currentSelectedRecipe,
                });

                let currentStartDate;
                currentMealPlanStartDate.subscribe((value) => {
                    currentStartDate = value;
                })();

                const userHasMilestone = await hasMilestone("addedFirstMeal");

                if (userHasMilestone) {
                    showToast(
                        `Added ${currentSelectedRecipe.label} to ${mealPlanDay}`,
                    );
                } else {
                    await setMilestone("addedFirstMeal");

                    // ensure that the recipe search popup is closed, show the user where their recipes are going
                    window.Framework7ComponentsApp.f7.popup.close(
                        ".recipe-search",
                    );

                    window.Framework7ComponentsApp.f7.sheet.open(
                        ".onboarding-modal-add-recipe",
                    );
                }
            } catch (error) {
                debugger;
            }
        }

        f7.tab.show("#view-home");

        // TODO: Clear search
    }

    async function handleFavouriteRecipe() {
        let currentRecipeDetails;
        selectedRecipe.subscribe((value) => {
            currentRecipeDetails = value;
        });

        if (currentRecipeDetails.id) {
            // Dealing with a Zestyplan recipe
            await addNewFavourite({
                recipeId: currentRecipeDetails.id,
            });
        } else {
            // Dealing with an edamam recipe
            // We're only going to take the URI here
            await addNewFavourite({
                recipeUri: currentRecipeDetails.uri,
            });
        }
    }

    function recipeFavourited(favouritesList) {
        if (favouritesList.length == 0) {
            return false;
        }

        let currentRecipeDetails;
        selectedRecipe.subscribe((value) => {
            currentRecipeDetails = value;
        });

        if (currentRecipeDetails.id) {
            // Dealing with a Zestyplan recipe

            if (
                favouritesList.find(
                    (favourite) =>
                        favourite.recipe_id == currentRecipeDetails.id,
                )
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            // Dealing with an edamam recipe
            // We're only going to take the URI here

            if (
                favouritesList.find(
                    (favourite) =>
                        favourite.recipe_uri == currentRecipeDetails.uri,
                )
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    async function handleUnFavouriteRecipe() {
        let currentRecipeDetails;
        selectedRecipe.subscribe((value) => {
            currentRecipeDetails = value;
        });

        if (currentRecipeDetails.id) {
            // Dealing with a Zestyplan recipe
            await removeFavourite({
                recipeId: currentRecipeDetails.id,
            });
        } else {
            // Dealing with an edamam recipe
            await removeFavourite({
                recipeUri: currentRecipeDetails.uri,
            });
        }
    }

    async function generateZestyImpactBreakdown() {
        // TODO: Send this all to the zestyImpactBreakdown variable at the end
        let currentRecipe;
        selectedRecipe.subscribe((value) => {
            currentRecipe = value;
        });

        // TODO: Pass in the current recipe ID with internal/external context
        // TODO: Have the server function insert the new row into the database for future users
        // TODO: Set RLS policy of the recipe_impact_v1 table to be readable by any authenticated users
        // TODO: Just can't insert/delete/update
        let generatedImpactData = await supabase.functions.invoke(
            "co2-emission-calculator",
            {
                body: {
                    recipeType: currentRecipe.type
                        ? currentRecipe.type
                        : null,
                    recipeId: currentRecipe.id ? currentRecipe.id : null,
                    recipeUri: currentRecipe.uri ? currentRecipe.uri : null,
                    ingredients: currentRecipe.ingredients,
                },
            }
        );

        if (generatedImpactData.error) {
            zestyImpactBreakdown = "error"; // tell the UI to not render the breakdown
            showToast(`Error generating impact breakdown.`);
        } else {
            zestyImpactBreakdown = generatedImpactData.data;
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function invokeShare(recipe) {
        let shareUrl;
        if (recipe.type == "internal") {
            // Internal recipe
            shareUrl = `https://app.zestyplan.com?method=view_recipe&recipe_id=${recipe.id}&external=false`;
        } else {
            // External recipe
            debugger;
            shareUrl = `https://app.zestyplan.com?method=view_recipe&recipe_id=${
                recipe.uri.split("#")[1].split("_")[1]
            }&external=true`;
        }

        if (navigator.share && window.cordova) {
            navigator
                .share({
                    title: recipe.label,
                    url: shareUrl,
                    chooserTitle: "Share this recipe",
                })
                .then(() => {
                    showToast("Successfully shared!");
                })
                .catch(function (err) {
                    // If ABORT_ERR is seen, then the user simply cancelled the share
                    if (!err.ABORT_ERR) {
                        showToast("An error has occurred when sharing.");
                        throw new Error({
                            errorName: "Share error",
                            details: err,
                        });
                    }
                });
        } else if (window.plugins && window.plugins.socialsharing) {
            // this is the complete list of currently supported params you can pass to the plugin (all optional)
            var options = {
                subject: recipe.label,
                url: shareUrl,
            };

            var onSuccess = function (result) {
                f7.dialog.alert("Success!");
            };

            var onError = function (msg) {
                f7.dialog.alert("Sharing failed with message: " + msg);
            };

            window.plugins.socialsharing.shareWithOptions(
                options,
                onSuccess,
                onError,
            );
        } else {
            // Fallback for when share API is not supported
            if (navigator.clipboard) {
                // Check if the browser supports the Clipboard API
                navigator.clipboard
                    .writeText(shareUrl)
                    .then(function () {
                        showToast("Link copied to clipboard!");
                    })
                    .catch(function (err) {
                        debugger;
                        showToast("Unable to copy to clipboard on this device");
                    });
            } else {
                // Fallback for browsers without clipboard API support
                showToast("Unable to copy to clipboard on this device");
            }
        }
    }

    function showEnvironmentalReplacementSheet({
        ingredientId,
        itemName,
        totalCO2Impact,
        co2Explaination,
        replacements,
    } = {}) {
        environmentalIngredientReplacementSheetData.set({
            ingredientId,
            itemName,
            totalCO2Impact,
            co2Explaination,
            replacements,
        });


        f7.sheet.open(".environmental-ingredient-replacement-sheet");
    }

    function calculateCO2Improvement(highImpact, lowImpact) {
        let improvement = (highImpact - lowImpact) / highImpact * 100;
        return improvement.toFixed(2) + '%';
    }

    async function triggerSwapIngredient(){
        const currentIngredient = f7.$('input[name="ingredient-replacement"]:checked').val();

        // Cet current store value of environmentalIngredientReplacementSheetData
        let currentData;
        environmentalIngredientReplacementSheetData.subscribe((value) => {
            currentData = value;
        })();

        // Get ID of ingredtient to swap
        const ingredientId = currentData.ingredientId;

        // Pass data to swapRecipeIngredient
        try {
            await swapRecipeIngredient({
                ingredientId,
                newIngredientName: currentIngredient
            })
            showToast('Ingredient swapped');

            // Close the sheet
            f7.sheet.close(".environmental-ingredient-replacement-sheet");

            // Refresh the current recipe details
            let updatedRecipe = await getRecipeDetails($selectedRecipe.id, {});
            selectedRecipe.set(updatedRecipe)
        } catch (error) {
            console.log(error);
            showToast('Error swapping ingredient');
        }

    }
</script>

<Popup
    push
    swipeToClose
    class="recipe-details-popup"
    onPopupClose={recipeDetailsClose}
    onPopupClosed={recipeDetailsClosed}
>
    <View>
        <Page
            name="recipe-details"
            class="recipe-details {addToMealPlan
                ? 'recipe-add-to-mealplan'
                : ''}"
        >
            <Navbar transparent>
                <NavLeft>
                    <Link popupClose>
                        <i
                            class="fa fa-xmark navbar-icon"
                            style="padding-left: 10px"
                        ></i>
                    </Link>
                </NavLeft>
                <NavRight style="margin-right: 10px; margin-top: 10px;">
                    {#if $selectedRecipe}
                        {#if $selectedRecipe.createdBy == $userDataStore.id}
                            <Link
                                onClick={() => {
                                    f7.popup.open(".recipe-editor");
                                }}
                            >
                                <i
                                    class="fa-solid fa-pen-to-square navbar-icon"
                                />
                            </Link>
                        {/if}

                        {#if !recipeFavourited($userRecipeFavourites)}
                            <Link
                                onClick={() => {
                                    handleFavouriteRecipe();
                                }}
                            >
                                <i class="fa fa-regular fa-heart navbar-icon" />
                            </Link>
                        {:else}
                            <Link
                                onClick={() => {
                                    handleUnFavouriteRecipe();
                                }}
                            >
                                <i class="fa-solid fa-heart navbar-icon" />
                            </Link>
                        {/if}
                        <Link
                            onClick={() => {
                                invokeShare($selectedRecipe);
                            }}
                        >
                            <i
                                class="fa fa-arrow-up-from-bracket navbar-icon"
                            />
                        </Link>
                    {/if}
                </NavRight>
            </Navbar>
            {#if $selectedRecipe}
                <div
                    class="recipe-header"
                    style="background-image: url('{$selectedRecipe['backgroundImage']}');"
                />
                <div class="recipe-title-container">
                    <div class="recipe-title">
                        {$selectedRecipe["label"]}
                    </div>
                    <div class="recipe-subtitle">
                        {#if $selectedRecipe && $selectedRecipe["url"]}
                            <a
                                href="#"
                                on:click={() => {
                                    clickLink($selectedRecipe["url"]);
                                }}
                            >
                                By: {$selectedRecipe["publisher"] ||
                                    $selectedRecipe["source"]}
                            </a>
                        {:else}
                            By: {$selectedRecipe["publisher"] ||
                                $selectedRecipe["source"]}
                        {/if}
                    </div>
                    {#if $selectedRecipe && $selectedRecipe["description"]}
                        <div style="margin-top: 15px;">
                            {$selectedRecipe["description"]}
                        </div>
                    {/if}
                </div>
                <table class="recipe-metrics">
                    <tr>
                        <td
                            style="
                        width: 25%;
                        border-right-style: solid;
                        border-right-width: 1px;
                        border-right-color: #E5E5E5;
                    "
                        >
                            <span class="recipe-metric-title">Ready in</span><br
                            />
                            <span class="recipe-metric-value">
                                {convertMinutesToHours(
                                    $selectedRecipe["totalTime"],
                                )}
                            </span>
                        </td>
                        <td
                            style="
                        width: 25%;
                        border-right-style: solid;
                        border-right-width: 1px;
                        border-right-color: #E5E5E5;
                    "
                        >
                            <span class="recipe-metric-title">Servings</span><br
                            />
                            <span class="recipe-metric-value">
                                {$selectedRecipe["yield"]}
                            </span>
                        </td>
                        {#if $selectedRecipe["calories"]}
                            <td
                                style="
                            width: 25%;
                            border-right-style: solid;
                            border-right-width: 1px;
                            border-right-color: #E5E5E5;
                        "
                            >
                                <span class="recipe-metric-title">Calories</span
                                ><br />
                                <span class="recipe-metric-value">
                                    {Math.round(
                                        $selectedRecipe["calories"] /
                                            $selectedRecipe["yield"],
                                    )}
                                </span>
                            </td>
                        {:else}
                            <td
                                style="
                            width: 25%;
                            border-right-style: solid;
                            border-right-width: 1px;
                            border-right-color: #E5E5E5;
                        "
                            >
                                <span class="recipe-metric-title"
                                    >Difficulty</span
                                ><br />
                                <span class="recipe-metric-value">
                                    {#if $selectedRecipe["difficultyRating"]}
                                        {$selectedRecipe["difficultyRating"]}
                                    {:else}
                                        N/A
                                    {/if}
                                </span>
                            </td>
                            <td
                                style="
                        width: 25%;
                        "
                            >
                                <span class="recipe-metric-title"
                                    >My Rating</span
                                ><br />
                                <span class="recipe-metric-value">
                                    {#if $selectedRecipe["personalRating"]}
                                        {$selectedRecipe["personalRating"]}
                                    {:else}
                                        N/A
                                    {/if}
                                </span>
                            </td>
                        {/if}
                    </tr>
                </table>
                
                {#if !zestyImpactBreakdown} 
                    <!-- Skeleton loader of impact breakdown -->
                    <List mediaList accordionList inset outline>
                        <ListItem>
                            <div slot="title">
                                <span style="font-weight: 700;"
                                    >CO<sub>2</sub> impact</span
                                > (Beta)
                            </div>
                            <div
                                slot="subtitle"
                                class="skeleton-text skeleton-effect-wave"
                            >
                                Analyzing recipe...
                            </div>
                            <div
                                class="zesty-tip-icon skeleton-block skeleton-effect-wave"
                                slot="media"
                            >
                                <!-- 
                            <i class="fa fa-leaf" style="font-size: 24px;"></i>
                            
                            <Preloader color="white" />
                            -->
                            </div>
                        </ListItem>
                    </List>
                {/if}
                {#if zestyImpactBreakdown && zestyImpactBreakdown != "error"}
                    <!-- Full -->
                    <List mediaList accordionList inset outline>
                        <ListItem accordionItem>
                            <div slot="title">
                                <span style="font-weight: 700;"
                                    >CO<sub>2</sub> impact</span
                                > (Beta)
                            </div>
                            <div slot="subtitle">
                                {zestyImpactBreakdown
                                    ? zestyImpactBreakdown.totalCO2Impact.toFixed(
                                          2,
                                      )
                                    : "???"} lbs/g
                            </div>
                            <div class="zesty-tip-icon" slot="media">
                                <i class="fa fa-leaf" style="font-size: 24px;"
                                ></i>
                            </div>
                            <AccordionContent>
                                <Block class="impact-title">
                                    {zestyImpactBreakdown
                                        ? capitalizeFirstLetter(
                                              zestyImpactBreakdown.impactRating,
                                          )
                                        : "???"}
                                </Block>
                                <Block>
                                    <p>
                                        {zestyImpactBreakdown
                                            ? zestyImpactBreakdown.explanation
                                            : "???"}
                                    </p>
                                    <p
                                        style="color: #888888; font-size: 0.8em;"
                                    >
                                        * CO2 impact calculation functionality
                                        is currently in Beta. Results may be
                                        innacurate.
                                    </p>
                                </Block>
                            </AccordionContent>
                        </ListItem>
                    </List>
                {/if}
                <Block>
                    <Segmented
                        strong
                        tag="p"
                        class="activeIngredientInstructionSwitcher"
                    >
                        <Button
                            active={activeIngredientInstructionSwitcher === 0}
                            onClick={() =>
                                (activeIngredientInstructionSwitcher = 0)}
                            >Ingredients</Button
                        >
                        <Button
                            active={activeIngredientInstructionSwitcher === 1}
                            onClick={() =>
                                (activeIngredientInstructionSwitcher = 1)}
                            >Instructions</Button
                        >
                    </Segmented>
                </Block>
                {#if activeIngredientInstructionSwitcher === 0}
                    {#if $selectedRecipe.planRecipeId}
                        <div class="block-title">
                            <div
                                style="float: right; color: var(--f7-theme-color);"
                                on:mousedown={addAllItemsToGroceryCart}
                            >
                                + All to cart
                            </div>
                        </div>
                    {/if}
                    <List dividersIos mediaList outlineIos strongIos inset>
                        {#if $selectedRecipe["ingredients"]}
                            {#each $selectedRecipe["ingredients"] as ingredient}
                                {@const environmentalData =
                                    findMatchingIngredient(ingredient.name)}
                                <li>
                                    <div class="item-content">
                                        {#if ingredient.image}
                                            <div class="item-media">
                                                <img
                                                    style="border-radius: 8px"
                                                    src={ingredient.image}
                                                    width="40"
                                                    alt=""
                                                />
                                            </div>
                                        {/if}
                                        <div
                                            class="item-inner"
                                            style="display: flex; align-self: center;"
                                        >
                                            <div class="ingredient-details">
                                                <div class="item-title-row">
                                                    <div class="item-title">
                                                        {ingredient.name}
                                                    </div>
                                                </div>
                                                <div class="item-subtitle">
                                                {#if ingredient.quantity}
                                                    {ingredient.quantity}
                                                    {ingredient.unit
                                                        ? ingredient.unit
                                                        : ""}
                                                {/if}
                                                {#if 
                                                    environmentalData && 
                                                    environmentalData.item && 
                                                    environmentalData.item.replacements && 
                                                    environmentalData.item.replacements.length > 0
                                                }

                                                    <div style="display: flex;">
                                                        <Link onClick={() => {
                                                            // Extract vars to send to showEnvironmentalReplacementSheet
                                                            const ingredientId =
                                                                ingredient.id;
                                                            const totalCO2Impact =
                                                                environmentalData
                                                                    .item
                                                                    .co2_impact_kg;
                                                            const co2Explaination =
                                                                environmentalData
                                                                    .item
                                                                    .co2_explanation;
                                                            const replacements =
                                                                environmentalData
                                                                    .item
                                                                    .replacements;

                                                            // Trigger showEnvironmentalReplacementSheet
                                                            showEnvironmentalReplacementSheet(
                                                                {
                                                                    ingredientId,
                                                                    itemName: ingredient.name,
                                                                    totalCO2Impact,
                                                                    co2Explaination,
                                                                    replacements,
                                                                },
                                                            );
                                                        }}>
                                                            <Chip 
                                                                text='{environmentalData.item.co2_impact_kg} kg CO₂e'
                                                                textColor="white"
                                                                class="md"
                                                                style="
                                                                    background-color: rgb(255, 143, 0);
                                                                    font-weight: 700;
                                                                "
                                                            >
                                                                <span slot="media">
                                                                    <i class="fa fa-leaf"></i>
                                                                </span>
                                                            </Chip>
                                                            <Button round style="padding: 0;">
                                                                {#if $selectedRecipe && $selectedRecipe.createdBy == $userDataStore.id}
                                                                    <!-- If the user owns the recipe, show the swapping icon -->
                                                                    <i
                                                                        class="fa fa-circle-info"
                                                                        style="font-size: 24px;"
                                                                    ></i>
                                                                {:else}
                                                                    <!-- If the user doesn't own the recipe, show just an info icon -->
                                                                    <i
                                                                        class="fa fa-circle-info"
                                                                        style="font-size: 24px;"
                                                                    ></i>
                                                                {/if}
                                                                
                                                            </Button>
                                                        </Link>
                                                        
                                                    </div>
                                                {/if}
                                                </div> 
                                            </div>
                                            <div
                                                class="item-after add-to-cart-button"
                                            >
                                                {#if $selectedRecipe.planRecipeId}
                                                    {#if ingredient.added}
                                                        <Button
                                                            fill
                                                            round
                                                            onClick={() =>
                                                                handleCartRemove(
                                                                    ingredient,
                                                                )}
                                                            style="margin-left: 15px;"
                                                            >- Added</Button
                                                        >
                                                    {:else}
                                                        <Button
                                                            outline
                                                            round
                                                            onClick={() =>
                                                                handleCartAdd(
                                                                    ingredient,
                                                                )}
                                                            style="margin-left: 15px;"
                                                            >+ Cart</Button
                                                        >
                                                    {/if}
                                                {/if}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            {/each}
                        {/if}
                    </List>
                {:else if $selectedRecipe["type"] === "external"}
                    <List dividersIos outlineIos strongIos inset>
                        <ListItem
                            title="View instructions on {$selectedRecipe[
                                'source'
                            ]}"
                            href={$selectedRecipe["url"]}
                            target="_blank"
                            external
                        />
                    </List>
                {:else if $selectedRecipe["instructions"]}
                    <List dividersIos mediaList outlineIos strongIos inset>
                        {#each $selectedRecipe["instructions"] as instruction, index}
                            <li class="instruction-item">
                                <div
                                    style="position: absolute; display: flex; width: 100%; justify-content: space-between; box-sizing: border-box; padding-right: 15px; margin-left: 15px; top: 10px;"
                                >
                                    <div style="font-weight: 700;">
                                        Step {index + 1}
                                    </div>
                                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                                </div>
                                <div
                                    class="item-content"
                                    style="padding-top: 15px;"
                                >
                                    <div
                                        class="item-inner"
                                        style="padding-top: 20px; padding-bottom: 20px;"
                                    >
                                        <div
                                            style="display: flex; flex-direction: column; width: 100%;"
                                        >
                                            <div class="input-field outline">
                                                {instruction["description"]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        {/each}
                    </List>
                {/if}
                {#if addToMealPlan}
                    <div class="static-floating-footer">
                        <Button
                            large
                            fill
                            on:click={() =>
                                f7.sheet.open("#add-recipe-date-selector")}
                            >Add to meal plan</Button
                        >
                    </div>
                {/if}
                <DateSelectSheetModal
                    id="add-recipe-date-selector"
                    callbackOnSave={handleAddRecipe}
                />
            {/if}
            
        </Page>
    </View>
    <Sheet
        class="environmental-ingredient-replacement-sheet"
        style="
            height: auto;
            max-height: 80%;
            overflow-y: auto;
            border-radius: 16px 16px 0px 0px;
        "
        swipeToClose
        backdrop
        push={false}
        containerEl=".recipe-details-popup"
        on:sheetOpen={() => {
            tick().then(() => {
                // Disable swipe to close on the parent popup to prevent accidental closing
                f7.popup.get('.recipe-details-popup').params.swipeToClose = false
            });
        }}
        on:sheetClosed={() => {
            tick().then(() => {
                // Re-enable swipe to close on the parent popup
                f7.popup.get('.recipe-details-popup').params.swipeToClose = true
            });
        }}
    >
        <div class="swipe-handler" />

        <PageContent style="padding-bottom: 0px;">
            <Block>
                <div style="font-weight: 700; font-size: 1.5em;">
                    {$environmentalIngredientReplacementSheetData.itemName}
                </div>
                <Chip 
                    text='{$environmentalIngredientReplacementSheetData.totalCO2Impact} kg CO₂e'
                    textColor="white"
                    class="md"
                    style="
                        background-color: rgb(255, 143, 0);
                        font-weight: 700;
                    "
                >
                    <span slot="media">
                        <i class="fa fa-leaf"></i>
                    </span>
                </Chip>
                <p style="font-size: 1.2em;">
                    {$environmentalIngredientReplacementSheetData.co2Explaination}
                </p>
            </Block>
            {#if $selectedRecipe && $selectedRecipe.createdBy == $userDataStore.id}
                <!-- If the user owns the recipe, show the ability to swap the ingredient items -->
                <Block style="margin-bottom: 0px;">
                    <div style="font-weight: 700; font-size: 1.2em;">
                        Eco-friendly alternatives:
                    </div>
                </Block>
                <div class="eco-replacement-scroller-container">
                    <div class="eco-replacement-scroller-inner">
                        {#each $environmentalIngredientReplacementSheetData.replacements as replacement, index}
                            <div class="eco-replacement-item">
                                <List class="md" style="margin: 0; width: 100%;">
                                    <ListItem
                                        radio
                                        radioIcon="start"
                                        name="ingredient-replacement"
                                        value={replacement.name}
                                        checked={index === 0}
                                    >  
                                        <span slot="title" style="font-weight: 700;">
                                            {replacement.name}
                                        </span>
                                        <div slot="footer" style="font-size: initial;">
                                            <Chip 
                                                text='{replacement.co2_impact_kg} kg CO₂e'
                                                textColor="white"
                                                style="
                                                    background-color: var(--f7-theme-color);
                                                    font-weight: 700;
                                                "
                                            >
                                                <span slot="media">
                                                    <i class="fa fa-leaf"></i>
                                                </span>
                                            </Chip>
                                            <span style="font-weight: 700; color: var(--f7-theme-color);">
                                                { calculateCO2Improvement($environmentalIngredientReplacementSheetData.totalCO2Impact, replacement.co2_impact_kg) }
                                            </span>
                                            CO₂e saved
                                            <br>
                                            <span style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
                                                {replacement.explanation}
                                            </span>
                                        </div>
                                    </ListItem>
                                </List>
                                
                            </div>
                        {/each}
                    </div>
                </div>
                <Block>
                    <Button 
                        large 
                        fill 
                        on:click={() => {
                            triggerSwapIngredient();
                        }}
                    >
                        Swap ingredients
                    </Button>
                </Block>
            {:else}
                <Block>
                    <p>
                        Eco-friendly alternatives are only available to the recipe owner.
                    </p>
                </Block>
            {/if}

        </PageContent>
            
        
    </Sheet>
</Popup>



<style>
    .navbar-icon {
        font-size: 24px;
        transition: color 0.3s ease;
        color: #fff;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    }

    :global(.navbar-transparent-visible .navbar-icon) {
        color: var(--f7-theme-color) !important;
        text-shadow: none !important;
    }

    :global(.recipe-details .page-content) {
        padding-top: 0px;
    }

    :global(.ios .toast) {
        border-radius: 8px;
        margin: 10px;
        max-width: -webkit-fill-available;
    }

    :global(.ios .toast.toast-bottom .toast-content) {
        padding-bottom: var(--f7-toast-padding-vertical);
    }

    .recipe-header {
        background-size: cover;
        background-position: center;
        width: 100%;
        transition: background-position-y 0.1s ease;
    }

    .recipe-header:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .recipe-title {
        font-size: var(--f7-block-title-large-font-size);
        font-weight: var(--f7-block-title-medium-font-weight);
    }

    .recipe-title-container {
        padding: 15px 15px 0px 15px;
        border-radius: 16px;
        margin-top: -15px;
        background-color: var(--f7-page-bg-color);
    }

    .recipe-metrics {
        width: 100%;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .recipe-metric-title {
        color: #a4a4a4;
    }

    .recipe-metric-value {
        font-size: 22px;
        font-weight: 700;
    }

    .ingredient-details {
        float: left;
    }

    .add-to-cart-button {
        float: right;
        height: 100%;
        align-items: center;
    }

    .block-title {
        margin-top: 20px;
        font-size: 18px;
    }

    :global(.dark .fade) {
        background-image: linear-gradient(
            transparent,
            rgba(255, 255, 255, 0) 70%,
            rgba(0, 0, 0, 1)
        );
    }

    :global(.recipe-add-to-mealplan .page-content) {
        padding-bottom: 120px;
    }

    :global(.recipe-details-popup) {
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    .activeIngredientInstructionSwitcher {
    }

    :global(.activeIngredientInstructionSwitcher a) {
        height: 40px;
    }

    .zesty-tip-icon {
        background-color: var(--f7-theme-color);
        min-width: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        border-radius: 50%;
        color: rgb(255, 255, 255);
    }

    :global(.impact-title) {
        font-weight: 700;
        font-size: 15px;
    }

    .eco-replacement-scroller-container {
        width: 100%;
        overflow: scroll;
        margin-top: 10px;
        margin-bottom: 5px;
        padding-bottom: 15px;
    }

    .eco-replacement-scroller-inner {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        row-gap: 10px;
        column-gap: 10px;
        width: 100%;
    }

    .eco-replacement-item {
        overflow: hidden;
        flex-shrink: 0;
        width: 80%;
        height: 100%;
        position: relative;
        transition-property: transform;
        display: block;
        border-style: solid;
        border-radius: 8px;
        height: auto;
        display: flex;
        align-items: center;
        border-color: #cecece;
    }
</style>
